@import '../../../../misc/colors.module.scss';
@import '../../../../misc/misc.module.scss';

.metric-unit-selector {
  position: relative;

  display: none;
  flex-shrink: 1;
  justify-content: right;

  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 10px;

  min-width: 100px;

  &:hover {
    cursor: pointer;

    svg {
      path {
        fill: $green1;
      }
    }
  }

  &__select {
    display: flex;
    font-size: 10px;

    justify-content: center;
    align-items: center;

    color: $l-gray2;

    > div {
      display: inline;
    }
  }

  &__icon {
    display: block;
    position: relative;
    height: 10px;
    line-height: 8px;
    text-align: center;

    svg {
      height: 4px;
      width: 10px;
      path {
        fill: $l-gray2;
      }
    }
  }

  &__unit-list {
    position: absolute;

    top: 100%;
    right: 0;

    background-color: $d-gray2;

    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    z-index: 1;

    font-size: 10px;

    overflow: hidden;

    .metric-unit-list-item {
      text-align: center;

      line-height: 1em;
      white-space: pre;

      padding: 0.3em 1em 0.3em 1em;

      &.active {
        color: $green1;
      }

      &:hover {
        cursor: pointer;

        background-color: $green2;
        color: $d-gray4;
      }

      &:last-child {
        padding-bottom: 0.6em;
      }
    }
  }
}
