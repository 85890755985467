@import '../../../misc/colors.module.scss';

.trade-chart-error {
  align-self: center;
  color: $gray4;
  text-align: center;
  padding: 2em;
  margin: 0 auto;
  width: 100%;

  .large {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .small {
    margin-top: 0.875rem;
    font-weight: normal;
    font-size: 0.75rem;
  }
}
