@import './misc/colors.module.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.debug {
  display: none;

  &.visible {
    display: block;
  }
}

.green {
  color: $green1;
}

.red {
  color: $red1;
}

.trade-modal-component-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 0;
  background: linear-gradient(180deg, #1c2527 0%, #14161a 80.73%, #121316 100%);

  .modal-component-heading {
    display: flex;
    justify-content: center;
    width: 100%;

    color: $l-gray3;
    text-transform: capitalize;
    align-items: flex-start;

    padding: 24px 0;

    &__main-text {
      font-size: 16px;
      font-weight: 500;
      width: fit-content;
    }
  }

  .close-modal-btn {
    position: absolute;
    left: 16px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-modal-btn:hover {
    cursor: pointer;

    svg {
      path {
        fill: $orange1;
      }
    }
  }
}

.modal-component-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-bottom: 8px;
  gap: 24px;

  padding: 30px;
  border: 1px solid #29292d;

  background: #211f24;
  box-shadow: 0px 35px 71px -47px rgba(82, 255, 177, 0.37);
  border-radius: 16px;

  overflow: hidden;

  &::before {
    content: ' ';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    backdrop-filter: blur(3px);
    background: radial-gradient(
        70.22% 56.77% at 51.87% 101.05%,
        rgba(79, 255, 176, 0.24) 0%,
        rgba(79, 255, 176, 0) 100%
      ),
      rgba(7, 8, 14, 0.7);
    z-index: -1;
  }

  .modal-component-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 4px;
    color: $l-gray3;
    text-transform: capitalize;
    align-items: center;

    &__main-text {
      font-size: 22px;
      font-weight: 500;
      background: linear-gradient(
          90deg,
          #4fffb0 1.27%,
          #b3ff8f 48.96%,
          #ff984e 104.14%
        ),
        linear-gradient(90deg, #4fffb0 1.27%, #a2ff76 53.24%, #ff984e 104.14%),
        linear-gradient(90deg, #ffce4f 1.27%, #4fffb0 104.14%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      width: fit-content;

      &__secondary {
        font-size: 16px;
        color: #d1dee8;
        text-transform: none;
        background: none;

        padding: 8px 0;

        -webkit-background-clip: none;
        -webkit-text-fill-color: #d1dee8;
        background-clip: none;
      }
    }
  }

  .close-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-modal-btn:hover {
    cursor: pointer;

    svg {
      path {
        fill: $orange1;
      }
    }
  }

  .modal-component-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    color: white;

    overflow-y: auto;

    padding: 0 16px 16px 0;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $gray3;
      margin-bottom: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent url('/misc/images/Scrollbar.svg') no-repeat;
      background-position: bottom;
    }
  }
}

// style me please
.__react_component_tooltip {
  // padding:  !important;
  font-weight: 600;
  letter-spacing: 0.5px;

  line-height: 1.2em;
}
