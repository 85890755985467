@import '../../../../../misc/colors.module.scss';
@import '../../../../../misc/misc.module.scss';

.active-account {
  min-width: 375px;
  max-width: 1050px;
  border-radius: $border-radius;
  background: linear-gradient(180deg, #1c2527 0%, #14161a 80.73%, #121316 100%);
  padding: 24px 0px 0px 0px;
  color: white;
  margin: 0px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__title {
    width: fit-content;
    color: $l-gray3;
    font-size: 22px;
    font-weight: 500;
    margin: 0px 32px 24px 32px;
    background: linear-gradient(
        90deg,
        #4fffb0 1.27%,
        #b3ff8f 48.96%,
        #ff984e 104.14%
      ),
      linear-gradient(90deg, #4fffb0 1.27%, #a2ff76 53.24%, #ff984e 104.14%),
      linear-gradient(90deg, #ffce4f 1.27%, #4fffb0 104.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    font-size: 18px;
    font-weight: 500;

    .item {
      width: 52%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      word-break: break-all;
      gap: 10px;

      &:first-child {
        width: 18%;
        word-break: normal;
      }
      &:last-child {
        width: 30%;
        justify-content: right;
        word-break: normal;
      }
    }

    &-header {
      border-radius: 0px !important;
      border-bottom: 1px solid rgb(41, 41, 45);
      background: transparent !important;

      .item {
        word-break: normal;
      }
    }
  }

  &-button {
    height: 40px;
    user-select: none;
    border-radius: 9999px;
    background-color: rgba(76, 243, 168, 0.12);
    color: #4fffb0;
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    word-break: normal;

    &:hover {
      background: rgba(76, 243, 168, 0.3);
    }

    &__label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      word-break: normal;
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

@media screen and (max-width: 45em) {
  .active-account {
    background: transparent;
    margin: 0px;

    &__title {
      margin: 0px 0px 24px 24px;
    }

    &-wrapper {
      width: fit-content;
      padding: 0px;
      background: linear-gradient(
        180deg,
        #1c2527 0%,
        #14161a 80.73%,
        #121316 100%
      );

      &:last-child {
        background: linear-gradient(
          180deg,
          #1c2527 0%,
          #14161a 80.73%,
          #121316 100%
        );
        width: 100%;
        border-top: 0px;
        border-radius: $border-radius;
      }

      .item {
        padding: 10px;
        width: 100% !important;

        &:first-child {
          padding: 20px 32px;
          border-top: 0px;
          border-radius: $border-radius $border-radius 0px 0px;
        }

        &:nth-child(even) {
          background: rgba(255, 255, 255, 0.06);
        }

        &:last-child {
          padding: 20px 32px;
          border-radius: 0px 0px $border-radius $border-radius;
        }
      }
    }

    &-button:last-child {
      margin-left: auto;
    }
  }
}
