$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2
);

//TODO RESET NO Bootstrap
@import '~bootstrap/scss/bootstrap';
@import './misc/defaults.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
