@import '../../../misc/colors.module.scss';
@import '../../../misc/misc.module.scss';

.account-selector {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;

  z-index: 3;

  &__content-wrapper {
    width: 100%;
    max-width: 460px;
    min-height: 500px;
    max-height: 690px;

    border-radius: $border-radius;

    &__create-account-link {
      text-decoration: none;
      font-weight: normal;
      color: $orange1;
    }

    .account-selector__message {
      padding: 16px;

      text-align: center;

      .account-selector__create-account-link {
        display: inline;
      }
    }
  }

  &__clear-button {
    display: flex;
    justify-content: center;

    button {
      width: auto;
      color: $gray4;
      background: none;
      line-height: 16px;
      padding: 16px;
      width: 100%;

      &:hover {
        background: none;
        color: $green1;
      }
    }
  }
}
