@import '../../misc/misc.module.scss';
@import '../../misc/colors.module.scss';

.trade-page-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  padding: 16px;

  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.trade-page-toggles {
  display: flex;
  font-size: 12px;
  color: $d-gray6;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  padding: 0 12px 12px 12px;
  color: $green1;
  gap: 12px;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4px;
    cursor: pointer;
    padding: 4px 12px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px rgba($green1, 0.2) inset;

    &:hover {
      box-shadow: 0 0 0 1px $green1 inset;
    }

    &.active {
      background-color: $green1;
      color: $d-gray6;

      svg {
        path {
          fill: $d-gray6;
        }
      }
    }
  }
}

.trade-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100px;

  &__content {
    display: flex;
    flex-basis: 450px;
    max-width: 100vw;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 1;
    overflow: visible;
    height: auto;
    position: relative;
    gap: 10px;
  }
}

.notifications-bar {
  top: 16px;
  right: 40px;
  position: fixed;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.9);
  text-transform: capitalize;

  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 16px;
  width: 150px;
  z-index: 100;

  background-color: #121416;
  border-radius: 10px;

  transition: right 300ms ease;

  .notification-cancel-wrapper {
    right: -8px;
    top: -8px;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: $d-gray4;
    display: flex;
    justify-content: center;
    align-items: center;

    .notification-cancel-button {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0;
      background: none;
      user-select: none;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;

      path {
        fill: $white;
      }

      &:hover {
        svg {
          path {
            fill: $gray4;
          }
        }
      }

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  opacity: 1;
  visibility: visible;

  .notification-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px;
  }

  &.transaction-standby {
    right: -300px;
  }

  &.transaction-success {
    .notification-icon {
      background-image: url('../../misc/images/Success.svg');
    }
  }

  &.transaction-failed {
    .notification-icon {
      background-image: url('../../misc/images/Error.svg');
    }
  }

  &.transaction-pending {
    .notification-icon {
      background-image: url('../../misc/images/Spinner.png');

      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}
