@import '../../../../misc/colors.module.scss';
@import '../../../../misc/misc.module.scss';

.account-item {
  position: relative;
  cursor: pointer;

  border-radius: 12px;

  background-color: $gray3;

  padding: 1px;

  &--active,
  &:hover {
    background: linear-gradient(90deg, #4fffb0, #b3ff8f, #ff984e);
    .account-item__wrapper {
      &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background: linear-gradient(
            285.92deg,
            rgba(73, 228, 159, 0) 25.46%,
            rgba(228, 175, 73, 0.2) 98.29%
          ),
          rgba(76, 243, 168, 0.12);

        border-radius: 12px;
        z-index: -1;

        &__chain-name {
          color: $green1;
        }
      }

      background-color: #211f24;
      z-index: 1;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px 16px;

    top: 0;
    left: 0;

    border-radius: 12px;
  }

  &__address-entry {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__address-info {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__chain-info {
    position: relative;
    min-width: 100px;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    font-weight: 500;
    justify-content: space-between;

    &__left {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 100%;

      &__name {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: fit-content;
      }

      &__source {
        font-size: 10px;
        font-weight: 800;
        background: $gray2;
        color: $gray4;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
  }

  &:hover {
    .account-item__wrapper {
      &:before {
        background: #26282f;
        z-index: -1;
      }
    }
  }

  .account-item &__identicon {
    display: flex;
    width: 32px;
    height: 32px;
    line-height: 32px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    background-color: black;
    border-radius: 50%;

    svg {
      circle:first-child {
        fill: black;
      }

      position: relative;
    }
  }

  &__chain-name {
    font-size: 12px;
    line-height: 1.2em;
    font-weight: 400;
  }

  &__chain-address {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
