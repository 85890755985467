@import './../../misc/colors.module.scss';
@import './../../misc/misc.module.scss';
@import '../Button/Button.scss';
@import './../Trade/TradeForm/TradeForm.scss';

.pools-form-wrapper {
  @extend .trade-form-wrapper;

  .pools-form-heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .settings-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: left;
      right: 10px;
      top: 10px;
      gap: 10px;

      .pool-settings-button {
        display: flex;
        padding: 10px 10px;
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        background-color: rgba(162, 176, 187, 0.1);

        svg {
          width: 20px;
        }

        &:hover {
          cursor: pointer;

          svg {
            path {
              fill: $green1;
            }
          }
        }
      }

      .pool-page-tabs {
        display: flex;
        font-size: 12px;
        color: #1b1b1e;
        align-items: center;
        font-weight: 500;
        justify-content: center;
        color: #4fffb0;
        gap: 12px;

        .tab {
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap: 4px;
          cursor: pointer;
          padding: 4px 12px;
          border-radius: 16px;
          box-shadow: 0 0 0 1px rgb(79 255 176 / 20%) inset;

          &:hover {
            box-shadow: 0 0 0 1px #4fffb0 inset;
          }

          &.active {
            background-color: #4fffb0;
            color: #1b1b1e;
          }
        }
      }
    }
  }

  .pools-form {
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;

    .pools-form-heading {
      width: fit-content;
      padding: 4px 0;
      color: $l-gray3;
      font-size: 22px;
      font-weight: 500;
      background: linear-gradient(
          90deg,
          #4fffb0 1.27%,
          #b3ff8f 48.96%,
          #ff984e 104.14%
        ),
        linear-gradient(90deg, #4fffb0 1.27%, #a2ff76 53.24%, #ff984e 104.14%),
        linear-gradient(90deg, #ffce4f 1.27%, #4fffb0 104.14%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .divider-wrapper {
      display: flex;
      align-items: center;
      height: 1px;
      width: 100%;
    }

    .divider {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: rgba(76, 243, 168, 0.12);
      opacity: 1;
      border: 0;
      left: 0;
    }

    .balance-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      background: rgba(162, 176, 187, 0.1);
      padding: 12px 16px;
      border-radius: 12px;
      gap: 10px;
    }

    @media only screen and (max-width: 500px) {
      .balance-wrapper {
        padding-bottom: 45px;
      }
    }

    .balance-wrapper-share-tokens {
      @extend .balance-wrapper;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .submit-button {
      background: $green1;
      text-transform: uppercase;
      border-radius: 36px;
      height: 50px;

      color: $d-gray4;

      &:hover {
        background-color: $green2;
      }

      &:disabled {
        background-color: $l-gray5;
      }
    }
  }
}

.asset-switch {
  display: flex;
  height: 43px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .asset-switch-icon {
    position: absolute;
    left: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    background: #192022;
    border-radius: 50%;

    transition: transform 500ms ease;

    &:hover {
      cursor: pointer;

      transform: rotate(180deg);

      svg {
        path {
          fill: $green1;
        }
      }
    }
  }

  .asset-switch-price {
    position: absolute;
    right: 24px;
    background: #192022;

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      padding: 4px 14px;
      font-size: 11px;
      font-weight: 500;

      background: rgba(218, 255, 238, 0.06);
      border-radius: 7px;
    }
  }
}

.trade-settings-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1;

  .disclaimer {
    padding: 12px 24px;
    padding-top: 0px;
    font-size: 14px;
    color: $gray4;
  }

  .trade-settings {
    height: 100%;
  }

  .settings-section {
    padding: 12px 24px;
    background: linear-gradient(0deg, #171518, #171518), #1c1a1f;
  }

  .settings-field {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      flex-grow: 10;
    }

    input {
      flex-shrink: 10;
      flex-basis: 50px;
      width: 50px;
      text-align: center;

      border-radius: $border-radius;
    }
  }

  &.hidden {
    display: none;
  }
}

.debug-box {
  position: fixed;
  padding: 16px;
  right: 0;
  top: 0;

  height: 100%;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.8);
}

.max-button {
  font-size: 12px;
  font-weight: 400;
  color: $white1;
  padding: 4px 10px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  text-transform: capitalize;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
