@import '../../../misc/colors.module.scss';

.formatted-balance {
  display: flex;

  flex-direction: column;
  align-items: baseline;
  justify-self: end;
  justify-content: right;

  //font-weight: 400;
  letter-spacing: 0.5px;

  line-height: 1.2em;

  &__native,
  &__display-value {
    display: flex;
    flex-direction: row;

    &__value {
      white-space: pre;
      margin-right: 4px;
    }

    &__suffix {
      color: $gray4;
      font-weight: 500;
    }

    &__symbol {
      color: $white1;
    }
  }

  &__display-value {
    font-size: 0.8em;
    color: $white1;
    opacity: 0.8;
  }
}
