@import '../../../misc/colors.module.scss';
@import '../../../misc/misc.module.scss';

.balance-input {
  display: flex;

  height: 1em;
  width: 100%;
  border-radius: $border-radius;

  &.no-selector {
    border-radius: $border-radius;
    overflow: hidden;
  }

  &__input-wrapper {
    font-size: 18px;
    flex-grow: 1;
    position: relative;

    input {
      position: absolute;
      font-size: 1em;
      line-height: 1em;
      font-weight: 500;

      padding: 0;
      text-align: right;

      height: 100%;
      width: 100%;

      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    user-select: none;

    text-align: right;
    justify-content: right;

    border-radius: $border-radius;
  }
}
