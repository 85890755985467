@import '../../../misc/colors.module.scss';
@import '../../../misc/misc.module.scss';

.asset-balance-input {
  display: flex;
  position: relative;
  align-items: center;

  width: 100%;

  border-radius: $border-radius;

  .balance-input {
    height: 20px;
    border-radius: 0;

    input {
      background: transparent;
    }
  }

  &__asset-info {
    height: 100%;

    display: flex;
    align-items: center;
    gap: 8px;

    width: 130px;

    font-size: 0.95rem;
    font-weight: bold;
    cursor: pointer;

    .asset-icon {
      width: 32px;
      height: 32px;

      flex-basis: 32px;
      flex-shrink: 0;
      flex-grow: 0;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__names {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__ticker {
        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 16px;
        line-height: 18px;

        font-weight: 600;
        letter-spacing: 0.5px;
      }
      &__full-name {
        font-size: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &:hover {
      color: $green1;

      svg {
        path {
          fill: $green1;
        }
      }
    }
  }

  &.disabled &__input-wrapper {
    box-shadow: none;
    background-color: transparent;
  }

  &__input-wrapper {
    flex-grow: 1;
    padding: 16px 12px;
    background-color: rgba(218, 255, 238, 0.06);
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(255, 255, 238, 0.3);

    &__unit-selector {
      display: flex;
      justify-content: flex-end;

      &__asset-name {
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .horizontal-bar {
        position: relative;
        top: -1px;
      }
    }
  }
}
