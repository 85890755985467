@import './colors.module.scss';
@import './misc.module.scss';

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi-Variable.ttf') format('truetype-variations');
  font-weight: 300 900;
}

html,
body {
  font-family: 'Satoshi', sans-serif;
  /* Better Font Rendering */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(
      89.2% 89.2% at 50.07% 87.94%,
      #008a69 0%,
      #262f31 88.52%
    ),
    #2c3335;

  background-attachment: fixed;
  padding: 0;

  min-width: 370px;
  min-height: 400px;
  height: 100%;
}

div {
  box-sizing: border-box;
}

input {
  font-weight: bold;
  color: $white1;
  outline: none;
  border: none;
  background-color: $d-gray2;
  box-sizing: border-box;

  &:disabled {
    color: $l-gray4;
    background-color: transparent;
  }
}

.primary-text {
  background: $snek-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
