@import './../../misc/colors.module.scss';
@import './../../misc/misc.module.scss';

.button {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  user-select: none;

  border-radius: $border-radius;
  font-weight: bold;
  width: 100%;

  &--primary {
    height: 40px;
    user-select: none;
    border-radius: 9999px;
    width: fit-content;
    background-color: #4fffb0;
    color: #26282f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;

    &:hover {
      background-color: #41db96;
    }

    .label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  &--secondary {
    height: 40px;
    user-select: none;
    border-radius: 9999px;
    width: fit-content;
    padding: 8px 16px;
    color: $gray4;
    background: none;
    display: flex;
    justify-content: center;

    &:hover {
      color: $green1;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
