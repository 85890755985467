@import './../../../../misc/colors.module.scss';
@import './../../../../misc/misc.module.scss';

.trade-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  min-height: 100px;
  font-size: 12px;
  font-weight: 400;

  &__data {
    display: flex;
    flex-direction: column;

    justify-content: center;

    max-height: 120px;
    opacity: 1;

    transition: max-height 0.3s ease, opacity 0.15s ease;

    &.hidden {
      max-height: 0px;
      opacity: 0;
    }

    .data-piece {
      padding: 2px 0 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__label {
        color: #9ea9b1;
      }
      position: relative;

      &:not(:last-child):after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #26282f;
        bottom: 0;
      }
    }
  }

  .validation {
    opacity: 0;
    line-height: 16px;
    padding: 0 16px;
    height: 100%;
    max-height: 0px;
    overflow: hidden;

    transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
    border-radius: 8px;

    &.visible {
      max-height: 80px;
      padding: 16px;
      opacity: 1;
    }

    &.error {
      background: #e34a4a;
    }

    &.warning {
      background: rgba(211, 130, 0, 0.903);
    }
  }
}
