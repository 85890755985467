@import '../../misc/colors.module.scss';
@import '../../misc/misc.module.scss';
@import '../TradePage/TradePage.scss';

.wallet-page {
  min-width: 375px;
  max-width: 1050px;
  padding: 32px;
  color: white;
  margin: 0px auto;
  color: white;

  .active-account-button__label,
  .balance-list-button__label {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    word-break: normal;
  }

  .account-item__chain-address {
    font-size: 12px;
  }

  .active-account-wrapper {
    font-size: 14px;
    gap: 10px;
    padding: 0 32px 12px 32px;
  }

  .active-account__title {
    margin: 16px 32px;
  }

  .balance-list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-size: 12px;
    font-weight: 500;
  }

  .item {
    flex-wrap: wrap;
  }

  .account-item__heading__left {
    flex-wrap: wrap;
  }

  .modal-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .notifications-bar {
    @extend .notifications-bar;
  }
}

.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

.responsiveTable tbody {
  &:nth-of-type(1) {
    border-top: 1px solid rgb(41, 41, 45);
  }

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.06);
  }
  &:last-child {
    border-radius: 0px 0px $border-radius $border-radius;
  }
}

@media screen and (max-width: 45em) {
  .wallet-page {
    padding: 0px;
  }
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable tbody {
    padding-bottom: 20px;
    &:nth-of-type(1) {
      border-top: 0px;
    }

    &:nth-child(odd) {
      background: transparent;
    }
    &:last-child {
      border-radius: 0px 0px $border-radius $border-radius;
    }
  }

  .responsiveTable th {
    overflow-wrap: normal;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-radius: $border-radius;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */

    position: relative;

    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .active-account-wrapper .item:last-child,
  .balance-list-wrapper .item:last-child {
    padding: 0;
    border-radius: 0px 0px 16px 16px;
    flex-wrap: wrap;
  }

  .active-account-wrapper .item:nth-child(even) {
    padding: 0 12px 30px 12px;
  }

  .balance-list-wrapper .item:first-child {
    padding: 20px 0px;
    border-top: 0px;
    flex-basis: 160px;
    border-radius: 16px 16px 0px 0px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .active-account-wrapper .item:first-child {
    padding: 20px 10px;
    border-top: 0px;
    border-radius: 16px 16px 0px 0px;
  }

  .active-account-wrapper .item:nth-child(even),
  .balance-list-wrapper .item:nth-child(even) {
    background: rgba(255, 255, 255, 0);
    justify-content: left;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */

    display: none;

    /* Top/left values mimic padding */
  }
}
