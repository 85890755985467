@import '../../../../misc/colors.module.scss';
@import '../../../../misc//misc.module.scss';

.asset-selector-wrapper {
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  z-index: 2;

  font-weight: bold;

  .asset-selector {
    height: 100%;

    &__asset-item {
      border: 1px solid transparent;
      border-radius: 8px;

      background-color: rgba(0, 0, 0, 0.3);

      padding: 16px;

      cursor: pointer;

      &:hover {
        border-color: $green1;
        color: $green1;
      }

      &.active {
        background-color: $d-gray2;
      }

      .asset-balance-input__asset-info {
        width: 100%;
        box-shadow: none;
      }
    }

    &__asset-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__secondary-assets {
      opacity: 0.5;
    }
  }
}
