@import '../../../misc/colors.module.scss';

$disabledOpacity: 0.3;

// TODO: break down css into multiple files
.chart-header {
  height: auto;
  color: white;
  flex-basis: 100px;

  &__timer {
    padding-top: 10px;
    font-weight: 700;
  }

  &__pair-symbols {
    width: fit-content;

    div {
      font-size: 1.75rem;
      font-weight: 500;
    }
  }

  &__type-label {
    font-size: 0.875rem;
    margin-left: 0.75rem;
    align-self: center;
  }

  &__data {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &__in-asset {
      display: flex;
      align-items: center;

      font-size: 20px;
      line-height: 24px;
      height: 24px;
    }

    &__breakdown {
      font-weight: 400;
      color: $green1;
      display: flex;
      gap: 4px;

      font-size: 14px;

      &__granularity {
        opacity: 1;
        // TODO: review animations + transitions
        transition: opacity ease-in-out 80ms;
        position: relative;

        //TODO: change indicator seems to be changing size when disabled (??)
        &.disabled {
          opacity: $disabledOpacity;
        }
      }
    }
  }

  &__pool-info {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 16px;
    font-weight: 600;

    &__assets {
      display: flex;
      flex-direction: column;

      &__symbols {
        font-size: 24px;
        line-height: 24px;
      }

      &__full-name {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    user-select: none;

    &__granularity,
    &__graph-type {
      line-height: 0.625rem;

      &__individual {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        border-left: 0.0625rem solid $gray1;
        font-size: 0.625rem;

        line-height: 0.625rem;
        text-align: center;
        color: $gray4;

        &:first-of-type {
          text-align: left;
          padding-left: 0rem;
          border-left: none;
        }

        &:last-of-type {
          text-align: right;
          padding-right: 0rem;
        }

        &:hover {
          // TODO: animations for color changes
          color: $white1;
          cursor: pointer;
        }

        &.active {
          color: $pink1;
        }

        &.disabled {
          cursor: default;
          color: rgba($gray4, 0.3);
          cursor: not-allowed;

          &:hover {
            color: rgba($gray4, 0.3);
          }
        }
      }
    }

    &__graph-type {
      &__individual {
        &:first-of-type {
          text-align: left;
          padding-left: 0rem;
        }
        &:last-of-type {
          text-align: right;
          padding-right: 0rem;
        }
      }
    }
  }
}
