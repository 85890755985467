@import '../../../../../misc/colors.module.scss';
@import '../../../../../misc/misc.module.scss';
@import '../ActiveAccount/ActiveAccount.scss';

.vesting-claim {
  @extend .active-account;

  &__title {
    @extend .active-account__title;
  }

  &-wrapper {
    @extend .active-account-wrapper;

    font-size: 12px;

    .item {
      flex-wrap: wrap;
      width: 22.5%;
      &:last-child {
        width: 10%;
        justify-content: right;
      }
    }
  }

  &-button {
    @extend .active-account-button;
    &__label {
      @extend .active-account-button__label;
    }
  }

  &__fee {
    width: 100px;
    display: flex;
    justify-content: left;
  }
}

@media screen and (max-width: 45em) {
  .vesting-claim {
    @extend .active-account;

    &__fee {
      width: 100%;
      padding: 20px 32px;
      justify-content: left;

      &.pivoted {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tdBefore {
          display: block;
        }
      }
    }

    &-no-vesting {
      padding: 20px 32px;
    }

    &__title {
      @extend .active-account__title;
    }

    &-wrapper {
      @extend .active-account-wrapper;

      .item {
        padding: 10px;
        width: 100% !important;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;

        .tdBefore {
          display: block;
        }

        &:first-child {
          border-top: 0px;
          border-radius: $border-radius $border-radius 0px 0px;
        }

        &:nth-child(even) {
          background: rgba(255, 255, 255, 0.06);
        }

        &:last-child {
          border-radius: 0px 0px $border-radius $border-radius;
        }
      }
    }
  }
}
