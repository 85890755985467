@import '../../../misc/colors.module.scss';

//DONT TOUCH
.trade-chart {
  display: flex;
  flex-direction: column;
  flex-grow: 100;
  flex-shrink: 1;
  min-width: 370px;
  max-width: 700px;
  flex-basis: 370px;
  width: 1000px;

  border-radius: 16px;
  background: #121416;
  padding: 16px 16px 12px 16px;
  max-height: 560px;
  height: 100%;

  color: $l-gray3;

  .divider {
    margin: 24px 12px 12px 10px;
  }

  .legend {
    font-size: 12px;
    padding: 0 12px 10px 12px;
    display: flex;
    justify-content: space-between;

    &__item {
      display: flex;
      gap: 4px;
      align-items: center;
      flex-wrap: wrap;

      .line {
        width: 50px;
      }

      .history {
        height: 2px;
        background: $snek-gradient-reverse;
      }

      .prediction {
        border-top: 2px dashed $green1;
      }

      &__prediction-toggle {
        color: $l-gray3;
        padding: 2px 12px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px rgba($green1, 0.2) inset;

        &:hover {
          box-shadow: 0 0 0 1px $green1 inset;
        }

        &.active {
          background-color: $green1;
          color: $d-gray6;

          svg {
            path {
              fill: $d-gray6;
            }
          }
        }

        &:hover {
          cursor: pointer;
          color: $green2;
        }
      }
    }
  }

  &__chart-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 100px;

    flex-direction: column;

    &__chart-jail {
      height: 300px;
      position: relative;
      flex-grow: 1;
      padding: 30px 0 0 0;
      flex-shrink: 1;
      flex-basis: 100px;
    }
  }

  &__error-wrapper {
    flex-grow: 1;
    display: flex;
  }

  &__tooltip {
    $tooltipColor: #daffee;

    height: 100%;
    width: 1px;
    border-right: 1px $tooltipColor dashed;
    position: absolute;
    z-index: 0;
    top: 0;
    opacity: 1;
    pointer-events: none;
    transition: opacity ease-in 100ms;

    &__label {
      background: $tooltipColor;
      color: $black;
      position: absolute;
      top: 0;
      text-align: center;
      padding: 0.325rem 0.5rem;
      font-size: 0.75rem;
      font-weight: 500;
      transition: opacity ease-in 100ms;
      z-index: 1;
      border-radius: 4px;
    }
  }
}
